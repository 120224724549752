.scanner-page {
    font-family: 'Arial', sans-serif;
    text-align: center;
    padding: 20px;
    background: #f9f9f9;
    min-height: 100vh;
}

.scanner-content h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.scan-feedback {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.feedback-message {
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.feedback-message.success {
    background: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.feedback-message.fail {
    background: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.feedback-icon {
    font-size: 3rem;
    margin-bottom: 10px;
}

.feedback-icon.success {
    color: #28a745;
}

.feedback-icon.fail {
    color: #dc3545;
}

.new-scan-button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.new-scan-button:hover {
    background: #0056b3;
}

@media (max-width: 768px) {
    .feedback-message {
        width: 100%;
    }
}
