body {
    background-image: "../img/dumbbells-floor-gym-ai-generative.jpg"  no-repeat center center fixed; /* Add your image path here */
    background-size: cover; /* Ensures the image covers the entire background */
    opacity: 0.9; /* Slightly transparent background */
  }

/* General Styles */
.homepage-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
  }
  
  /* Header with Background Image */
  .headers {
    position: relative;
    height: 300px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .headers-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Dark Overlay */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    padding: 20px;
  }
  
  .gym-name {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .gym-description {
    font-size: 1.2rem;
    margin: 10px 0 0;
    max-width: 800px;
  }
  
  /* Card Styling */
  .card {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  /* Address Section */
  .address-section {
    text-align: left;
  }
  
  .address-section h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #444;
  }
  
  /* Slider Styling */
  .slider-container {
    margin: 20px 0;
  }
  
  .image-slide {
    text-align: center;
  }
  
  .image-slide {
    width: 100%;
    display: flex; /* To center the image */
    justify-content: center; /* Center the image horizontally */
    align-items: center; /* Center the image vertically if needed */
    overflow: hidden; /* Ensures the image does not overflow */
  }
  
  .gym-image {
    width: 100%; /* Takes full width of the slider */
    height: auto; /* Maintain the aspect ratio */
    object-fit: cover; /* Ensures the image covers the container without distortion */
  }
  
  
  .gym-image:hover {
    transform: scale(1.05);
  }
  
  /* Details Section */
  .details-section {
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }
  
  .equipment-section,
  .slots-section {
    flex: 1;
  }
  
  .equipment-section h2,
  .slots-section h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #444;
  }
  
  .quantity {
    color: #666;
    font-size: 0.9rem;
  }
  
  /* Subscription Section */
  .subscription-section h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #444;
  }
  
  .pricing-card {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .pricing-option {
    text-align: center;
    padding: 15px;
    border-radius: 8px;
    background-color: #eef2f7;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .pricing-option:hover {
    background-color: #dfe4ea;
    transform: translateY(-5px);
  }
  
  .price {
    font-size: 1.8rem;
    font-weight: bold;
    color: #2d3436;
    margin-top: 5px;
  }
  
  /* Loading Screen */
  .loading {
    text-align: center;
    padding: 50px;
  }
  
  .slider-container {
    width: 100%; /* Full width of its parent container */
    max-width: 100%; /* Ensure no additional constraints */
  }

  .modify-link {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff; /* Button background color */
    color: white; /* Button text color */
    text-decoration: none; /* Remove underline */
    border-radius: 4px; /* Rounded corners */
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .modify-link:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  

