/* Base Styles */
.bank-account-page {
  font-family: 'Poppins', sans-serif;
  padding: 30px 20px;
  background-color: #f4f6f9;
}

/* Page Title */
.bank-account-page h2 {
  margin: 0 0 20px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #007bff;
  text-align: center;
}

/* Card Styling */
.bank-account-page .card {
  border: none;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
}

.bank-account-page .card-header {
  background: linear-gradient(to right, #007bff, #0056b3);
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1rem 1.25rem;
  border-radius: 12px 12px 0 0;
  border: none;
}

.bank-account-page .card-body {
  background-color: #f9f9f9;
  padding: 1.5rem;
}

/* Modal */
.bank-account-page .modal-body {
  padding: 1.5rem;
}

.bank-account-page .modal-footer {
  justify-content: flex-end;
  gap: 10px;
  padding: 1rem 1.5rem;
}

/* Buttons */
.bank-account-page .btn {
  border-radius: 30px;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 0.95rem;
  transition: all 0.3s ease-in-out;
}

.bank-account-page .btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2);
}

.bank-account-page .btn-light {
  font-weight: 600;
  font-size: 0.9rem;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  color: #333;
}

.bank-account-page .btn-primary {
  background: linear-gradient(to right, #007bff, #0056b3);
  border: none;
  color: #fff;
  min-width: 130px;
}

.bank-account-page .btn-success {
  background: linear-gradient(to right, #28a745, #218838);
  border: none;
  color: #fff;
  min-width: 130px;
}

/* Form Inputs */
.bank-account-page .form-control {
  border-radius: 8px;
  padding: 10px 14px;
  margin-top: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

.bank-account-page .form-control:focus {
  border-color: #007bff;
  box-shadow: none;
  outline: none;
}

/* Responsive Layout */
@media (max-width: 768px) {
  .bank-account-page h2 {
    font-size: 1.6rem;
  }

  .bank-account-page .btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .bank-account-page .modal-footer {
    flex-direction: column;
    align-items: stretch;
  }
}

@media (max-width: 480px) {
  .bank-account-page h2 {
    font-size: 1.4rem;
  }

  .bank-account-page .form-control {
    font-size: 0.95rem;
  }

  .bank-account-page .card-body {
    padding: 1rem;
  }
}
