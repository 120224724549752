/* Header.css - Upgraded UI */

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  color: linear-gradient(to right, #0a3c25, #0c5635);
  padding: 0.5rem 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  z-index: 1000;
  height: 70px;
  display: flex;
  align-items: center;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}

.logo-container img {
  max-height: 50px;
  transition: transform 0.3s ease;
}
.logo-container img:hover {
  transform: scale(1.05);
}

.menu-button {
  display: none;
  background: none;
  border: none;
  color: #0c5635;
  font-size: 2rem;
  cursor: pointer;
}

.header-nav {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav-link {
  color: #637461;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.95rem;
  font-weight: 700;
  transition: background 0.3s ease, transform 0.3s ease;
}

.nav-link:hover,
.nav-link.active {
  
  color: #00ffc8;
  transform: scale(1.05);
}

.logout-button {
  background: #ff4d4f;
  border: none;
  color: #fff;
  padding: 0.5rem 1.2rem;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.logout-button:hover {
  background: #ff6b6d;
  transform: scale(1.05);
}

.scan-icon {
  color: #00ffc8;
  font-size: 1.5rem;
  margin-right: 1rem;
  cursor: pointer;
  transition: color 0.3s ease;
}


/* Mobile Styles */
@media (max-width: 768px) {
  .menu-button {
    display: block;
  }

  .header-nav {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    flex-direction: column;
    background: #1c1f3f;
    padding: 1.2rem;
    gap: 1rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
    animation: fadeDown 0.4s ease-in-out;
  }

  .header-nav.open {
    display: flex;
    align-items: center;
  }

  .nav-link,
  .logout-button {
    width: 100%;
    text-align: center;
  }
}

/* Tablet Styles */
@media (min-width: 768px) and (max-width: 1024px) {
  .menu-button {
    display: block;
  }

  .header-nav {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    flex-direction: column;
    background: #1c1f3f;
    padding: 1.2rem;
    gap: 1rem;
    animation: fadeDown 0.4s ease-in-out;
  }

  .header-nav.open {
    display: flex;
    align-items: center;
  }
}

/* Animation */
@keyframes fadeDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
