/* GymOnboardingComponent.css */

.onboard-wrapper {
    background: #ffffff;
    color: #333;
    padding: 3rem 1.5rem;
  }
  
  .onboard-title {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
    color: #22c55e;
  }
  
  .onboard-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  @media (min-width: 768px) {
    .onboard-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .onboard-card {
    background-color: #f9fafb;
    border-radius: 1rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    transition: transform 0.2s;
  }
  
  .onboard-card:hover {
    transform: scale(1.02);
  }
  
  .onboard-image {
    width: 100%;
    border-radius: 0.75rem;
    margin-bottom: 1rem;
  }
  
  .onboard-step {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .onboard-ready {
    font-size: 1.5rem;
    font-weight: 600;
    color: #16a34a;
    margin-bottom: 0.5rem;
  }
  
  .onboard-text {
    font-size: 1rem;
    color: #555;
  }
  