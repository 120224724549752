.equipment-container {
    font-family: 'Roboto', sans-serif; /* Use the modern font */
    background-color: #f9f9f9;
    padding: 20px;
    min-height: 100vh; /* Ensures full-page coverage */
}

.equipment-content {
    max-width: 900px;
    margin: 0 auto;
    background: #ffffff;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

h1, h2 {
    color: #333333;
    font-size: 2rem;
    margin-bottom: 20px;
}

.equipment-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 25px;
}

.equipment-table th, .equipment-table td {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 1rem;
    color: #555;
}

.equipment-table th {
    background-color: #007bff;
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
}

.equipment-icon, .selected-icon {
    width: 45px;
    height: 45px;
}

.btn {
    padding: 10px 15px;
    margin: 8px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn:hover {
    transform: scale(1.05);
}

.btn-add, .btn-save {
    background-color: #28a745;
    color: white;
}

.btn-add:hover, .btn-save:hover {
    background-color: #218838;
}

.btn-edit {
    background-color: #007bff;
    color: white;
}

.btn-edit:hover {
    background-color: #0056b3;
}

.btn-delete {
    background-color: #dc3545;
    color: white;
}

.btn-delete:hover {
    background-color: #c82333;
}

.add-equipment-form {
    margin-top: 25px;
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.add-equipment-form input, .add-equipment-form select {
    width: calc(50% - 10px);
    padding: 12px;
    margin: 5px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 1rem;
}

.add-equipment-form button {
    margin-top: 15px;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-equipment-form button:hover {
    background-color: #0056b3;
}

.selected-equipment {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
    padding: 20px;
    background-color: #e6f7ff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    color: #333;
}

.selected-equipment .selected-icon {
    width: 45px;
    height: 45px;
}

.selected-equipment-text {
    color: #007bff;
    font-weight: bold;
    font-size: 1rem;
}

.no-equipment {
    color: #888888;
    text-align: center;
    margin: 25px 0;
    font-size: 1.2rem;
}
