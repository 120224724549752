/* MapComponent.css */
.map-container {
    font-family: 'Arial', sans-serif;
    margin: 20px auto;
    padding: 20px;
    max-width: 800px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    background: #ffffff;
  }
  
  .map-title {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 15px;
  }
  
  .map-display {
    height: 400px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .current-location {
    display: block;
    width: 100%;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px;
    border: none;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .current-location:hover {
    background-color: #0056b3;
  }
  
  .mt-3 {
    margin-top: 15px;
  }
  
  input {
    width: calc(100% - 20px);
    margin: 0 auto 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  