/* Global styling */
body {
  background: linear-gradient(120deg, #e0eafc, #cfdef3);
  font-family: 'Segoe UI', sans-serif;
  margin: 0;
  padding: 0;
}

/* Main container */
.merged-gym-page {
  max-width: 1200px;
  margin: 80px auto;
  padding: 30px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(12px);
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  color: #333;
}

/* Card styles */
.gym-details-col .card,
.gallery-col .card {
  border: none;
  border-radius: 20px;
  background: white;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.gym-details-col .card:hover,
.gallery-col .card:hover {
  transform: scale(1.01);
}

.card-header {
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%) !important;
  color: #fff !important;
  padding: 20px 25px;
  font-size: 1.5rem;
  font-weight: 600;
}

/* Gallery styles */
.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.gallery-item {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s ease;
}

.gallery-item:hover {
  transform: scale(1.08);
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

/* Delete button */
.delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(255, 69, 58, 0.9);
  border: none;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: rgba(255, 69, 58, 1);
}

/* Upload button */
.upload-button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  color: white;
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  transition: background 0.3s ease;
}

.upload-button:hover {
  background: linear-gradient(135deg, #5a0cb3 0%, #2065e0 100%);
}

.upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* No image text */
.no-images {
  text-align: center;
  font-size: 1.5rem;
  color: #666;
  font-weight: 500;
  margin-top: 20px;
}

/* Button styling */
button.btn-primary,
button.btn-secondary {
  font-weight: 600;
  border-radius: 8px;
  padding: 8px 20px;
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.nav-button {
  padding: 10px 20px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.nav-button.prev {
  background: linear-gradient(90deg, #6c757d, #5a6268); /* Bootstrap secondary tone */
  color: #fff;
  border: none;
}

.nav-button.next {
  background: linear-gradient(90deg, #007bff, #0056b3); /* Bootstrap primary tone */
  color: #fff;
  border: none;
}

.nav-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

/* Responsive tweaks */
@media (max-width: 991px) {
  .merged-gym-page {
    padding: 20px;
  }

  .card-header {
    font-size: 1.25rem;
    flex-direction: column;
    gap: 10px;
  }

  .gallery-item {
    width: 150px;
    height: 150px;
  }

  .upload-button {
    font-size: 0.95rem;
    padding: 10px 18px;
  }

  button.btn-primary,
  button.btn-secondary {
    padding: 6px 16px;
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .gallery-item {
    width: 120px;
    height: 120px;
  }

  .delete-button {
    width: 24px;
    height: 24px;
    font-size: 0.8rem;
  }

  .card-header h2 {
    font-size: 1.2rem;
  }

  .upload-button {
    font-size: 0.85rem;
    padding: 8px 16px;
  }
}

@media (max-width: 576px) {
  .gallery-item {
    width: 100px;
    height: 100px;
  }

  .upload-button {
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  .gallery {
    flex-direction: column;
    align-items: center;
  }

  .card-header {
    text-align: center;
    font-size: 1rem;
  }

  .card-body {
    font-size: 0.9rem;
  }

  .btn-group {
    flex-direction: column;
    gap: 10px;
  }
}
