/* Register.css */

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.gym-registration-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  background-image: url('../img/yupluck-hero.png'); /* Replace with actual image path */
  background-size: cover;
  background-position: center;
  position: relative;
}

/* Optional dark overlay */
.gym-registration-container::before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.registration-card {
  position: relative;
  z-index: 2;
  background: #ffffff;
  border-radius: 20px;
  padding: 40px 30px;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: 40px;
  align-self: center;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
}

.card-body h2 {
  font-size: 2.2rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.form-section h4 {
  font-size: 1.4rem;
  color: #2575fc;
  margin-bottom: 20px;
  border-bottom: 2px solid #eee;
  padding-bottom: 8px;
}

.form-group label {
  font-weight: 600;
  color: #444;
  margin-bottom: 6px;
  display: block;
}

.form-control {
  border-radius: 10px;
  padding: 12px 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  background: #f9f9f9;
  color: #222;
}

.form-control:focus {
  border-color: #2575fc;
  background: #fff;
  box-shadow: 0 0 6px rgba(37, 117, 252, 0.3);
}

.btn-primary {
  background: linear-gradient(to right, #43e97b, #38f9d7);
  border: none;
  padding: 12px;
  border-radius: 30px;
  font-weight: bold;
  font-size: 1.1rem;
  color: #111;
  margin-top: 20px;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.alert-danger {
  background: #ffe6e6;
  color: #b30000;
  border-radius: 10px;
  padding: 15px;
}

.modal-header,
.modal-footer {
  background: #f9f9f9;
}

.modal-title {
  color: #2575fc;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .gym-registration-container {
    flex-direction: column;
    background-position: top;
  }

  .registration-card {
    margin: 30px auto;
  }
}
