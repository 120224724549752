/* === Container & Page Header === */
.subscription-container {
  max-width: 900px;
  margin: 80px auto 40px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.05);
  font-family: 'Poppins', sans-serif;
  color: #333;
}

/* Header */
.subscription-header {
  font-size: 2rem;
  font-weight: 700;
  color: #007bff;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* === Navigation Buttons === */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.back-button,
.next-button {
  background: linear-gradient(45deg, #007bff, #0056b3);
  color: white;
  border: none;
  padding: 10px 24px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.back-button:hover,
.next-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
}

/* === View Mode Boxes === */
.subscription-details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.subscription-price {
  flex: 1 1 calc(48% - 10px);
  background: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
}

.subscription-price:hover {
  transform: translateY(-2px);
}

.subscription-price h2 {
  font-size: 1rem;
  color: #444;
  margin-bottom: 6px;
}

.subscription-price p {
  font-size: 1.5rem;
  font-weight: 700;
  color: #007bff;
  margin: 0;
}

/* === Form Layout === */
.subscription-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  background: #f7f9fc;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.06);
  margin-top: 10px;
}

.form-group {
  flex: 1 1 calc(48% - 10px);
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 0.95rem;
  font-weight: 600;
  color: #555;
  margin-bottom: 6px;
}

.form-group input {
  padding: 10px 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  background: #fff;
  transition: border 0.3s ease;
}

.form-group input:focus {
  border-color: #007bff;
  outline: none;
}

/* === Action Buttons === */
.submit-button,
.cancel-button,
.edit-button {
  flex: 1 1 48%;
  background: linear-gradient(45deg, #007bff, #0056b3);
  color: white;
  border: none;
  padding: 12px 18px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 15px;
  transition: all 0.3s ease-in-out;
}

.submit-button:hover,
.edit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.25);
}

.cancel-button {
  background: linear-gradient(45deg, #6c757d, #5a6268);
}

.cancel-button:hover {
  box-shadow: 0 4px 12px rgba(108, 117, 125, 0.3);
}

/* === Responsive Design === */
@media (max-width: 768px) {
  .subscription-price,
  .form-group {
    flex: 1 1 100%;
  }

  .button-container {
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
  }

  .back-button,
  .next-button,
  .submit-button,
  .cancel-button,
  .edit-button {
    width: 100%;
  }
}
