.gym-display {
  background-color: #f7f9fc;
  font-family: 'Poppins', sans-serif;
  min-height: 100vh;
  overflow-x: hidden;
}

.hero-section {
  background: linear-gradient(to bottom, rgba(255, 126, 95, 0.8), rgba(254, 180, 123, 0.8)), url('../img/hero-background.jpg') center/cover no-repeat;
  color: white;
  text-align: center;
  padding: 5rem 2rem;
  margin-bottom: 2rem;
  border-radius: 0 0 50px 50px;
  position: relative;
  animation: fadeInDown 1s ease-in-out;
}
.progress-step-icon {
  margin-right: 8px;
  color: #007bff;
}

.clickable-progress {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
  display: flex;
  align-items: center;
}

.clickable-progress:hover {
  color: #0056b3;
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.heading {
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.subheading {
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 1px;
}

.profile-container {
  max-width: 1200px;
  margin: auto;
  padding: 2rem;
}

.progress-section {
  background: #fff;
  border-radius: 15px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s;
}

.progress-section:hover {
  transform: scale(1.02);
}

.custom-progress {
  height: 10px;
  border-radius: 10px;
}

.steps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.step-card {
  background: #fff;
  border: none;
  border-radius: 15px;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.step-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.icon-wrapper {
  background: linear-gradient(to bottom, #ff7e5f, #feb47b);
  padding: 1.5rem;
  border-radius: 50%;
  margin: 0 auto 1rem;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.step-icon {
  font-size: 2rem;
}

.step-button {
  background: linear-gradient(to right, #ff7e5f, #feb47b);
  border: none;
  border-radius: 5px;
  color: white;
  padding: 0.5rem 1rem;
  transition: transform 0.3s, background-color 0.3s;
}

.step-button:hover {
  background: linear-gradient(to left, #feb47b, #ff7e5f);
  transform: scale(1.05);
}


.progress-section {
  background: linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%);
  border-radius: 20px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.progress-header {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #333;
  font-weight: 700;
}

.progress-steps {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 1rem;
  color: #555;
  text-align: left;
}

.progress-steps li {
  background: #f9f9f9;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.progress-steps li:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.custom-progress {
  height: 20px;
  border-radius: 10px;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  background: #e9ecef;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.2);
}

.progress-message {
  margin-top: 1rem;
  font-weight: 600;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-align: center;
}

.progress-message.warning {
  color: #ffc107;
  background: #fff8e5;
  border-radius: 10px;
  padding: 0.8rem 1.5rem;
  box-shadow: 0px 4px 10px rgba(255, 193, 7, 0.3);
}

.progress-message.success {
  color: #28a745;
  background: #eaffea;
  border-radius: 10px;
  padding: 0.8rem 1.5rem;
  box-shadow: 0px 4px 10px rgba(40, 167, 69, 0.3);
}

.progress-icon {
  font-size: 1.5rem;
  display: inline-block;
}

.card-title {
  font-size: 1rem;
}


@media (max-width: 768px) {
  .hero-section {
    padding: 3rem 1rem;
  }

  .heading {
    font-size: 2.2rem;
  }

  .steps-grid {
    grid-template-columns: 1fr;
  }
}
