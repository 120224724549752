/* Notification.css */


  
  .notifications-container {
    background: linear-gradient(135deg, #f0f4f8, #cfd9df);
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: 200px;
  }
  
  h2 {
    text-align: center;
    color: #333;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .list-group {
    list-style: none;
    padding: 0;
  }
  
  .list-group-item {
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 15px;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .list-group-item:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  }
  
  /* Profile image */
  .profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
    border: 2px solid #007bff;
  }
  
  /* Notification content */
  .notification-content {
    display: flex;
    align-items: center;
  }
  
  .notification-content i {
    font-size: 24px;
    margin-right: 10px;
    color: #007bff;
  }
  
  .notification-content span {
    font-size: 18px;
    color: #555;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .container {
      padding: 10px;
    }
  
    .list-group-item {
      padding: 10px;
    }
  
    .profile-img {
      width: 40px;
      height: 40px;
    }
  
    .notification-content span {
      font-size: 16px;
    }
  }
  