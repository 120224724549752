/* General Container */
.slot-container {
    padding: 30px 20px;
    background: linear-gradient(to right, #f9f9f9, #ffffff);
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
    color: #333;
  }
  
  /* Page Heading */
  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #222;
    text-align: center;
    margin: 40px 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
  
  /* Add Slot Form */
  .add-slot-form {
    background: #ffffff;
    border-radius: 16px;
    padding: 25px 20px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.06);
    margin-bottom: 30px;
  }
  
  .add-slot-header {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .form-fields {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  
  .form-fields select,
  .form-fields input {
    flex: 1 1 30%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    background: #f8f8f8;
  }
  
  /* Table Card */
  .slot-card {
    background: #fff;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.06);
    overflow-x: auto;
  }
  
  /* Table Styling */
  .slot-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .slot-table th,
  .slot-table td {
    padding: 12px 14px;
    border: 1px solid #e0e0e0;
    font-size: 0.95rem;
    text-align: center;
  }
  
  .slot-table th {
    background: #007bff;
    color: white;
    font-size: 1rem;
  }
  
  .slot-table tr:hover {
    background-color: #f1f7ff;
  }
  
  /* Buttons */
  .add-button,
  .save-button,
  .cancel-button,
  .toggle-button,
  .back-button,
  .next-button {
    padding: 10px 20px;
    border-radius: 25px;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .add-button {
    background: linear-gradient(90deg, #43e97b, #38f9d7);
    color: white;
    border: none;
  }
  
  .add-button:hover {
    transform: scale(1.03);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .save-button {
    background: #28a745;
    color: white;
    border: none;
  }
  
  .cancel-button {
    background: #dc3545;
    color: white;
    border: none;
  }
  
  .toggle-button {
    background: #ffc107;
    color: black;
    border: none;
  }
  
  .back-button,
  .next-button {
    background-color: #007bff;
    color: white;
    width: 120px;
  }
  
  /* Status Tags */
  .active-status,
  .inactive-status {
    padding: 5px 12px;
    font-weight: 600;
    border-radius: 20px;
    font-size: 0.9rem;
    display: inline-block;
  }
  
  .active-status {
    background-color: #28a745;
    color: white;
  }
  
  .inactive-status {
    background-color: #dc3545;
    color: white;
  }
  
  /* Empty Slot Message */
  .no-slot {
    padding: 20px;
    text-align: center;
    font-size: 1.1rem;
    color: #777;
  }
  

  .button-container {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .back-button,
  .next-button {
    background: linear-gradient(to right, #007bff, #0056b3);
    color: white;
    border: none;
    border-radius: 30px;
    padding: 10px 22px;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .back-button:hover,
  .next-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .bulk-actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    margin: 20px 0;
  }
  
  .action-button {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    border-radius: 30px;
    font-weight: bold;
    font-size: 1rem;
    color: white;
    border: none;
    transition: all 0.3s ease;
  }
  
  .disable-all-button {
    background: linear-gradient(90deg, #f76b1c, #ffaf7b);
  }
  
  .disable-all-button:hover {
    background: linear-gradient(90deg, #ffaf7b, #f76b1c);
  }
  
  .enable-all-button {
    background: linear-gradient(90deg, #6fcf97, #66d9e8);
  }
  
  .enable-all-button:hover {
    background: linear-gradient(90deg, #66d9e8, #6fcf97);
  }

  
  /* Responsive Tuning */
  @media (max-width: 768px) {
    .form-fields {
      flex-direction: column;
    }
  
    .form-fields select,
    .form-fields input {
      width: 100%;
    }
  
    .back-button,
    .next-button {
      width: 100%;
      margin-top: 10px;
    }
  
    .add-slot-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
  