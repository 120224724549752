.homepage {
  font-family: 'Poppins', sans-serif;
  background-color: #f5f6f8;
  color: #333;
}

.track-right h3 {
  margin-top: 30px;
  font-size: 1.4rem;
  color: #28a745;
}

.track-right p {
  margin-top: 10px;
  font-size: 1rem;
  color: #444;
}



/* Overall Header Styling */
.yupluck-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 32px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  z-index: 1000;
  position: relative;
  flex-wrap: wrap;
}

/* Left: Logo */
.yupluck-header-left .yupluck-logo {
  height: 50px;
  width: auto;
  transition: transform 0.3s ease;
}

.yupluck-logo:hover {
  transform: scale(1.05);
}

/* Center: App Store Icons */
.yupluck-header-center {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.store-badge {
  height: 45px;
  transition: transform 0.3s ease;
}

.store-badge:hover {
  transform: scale(1.05);
}

/* Right: Auth Buttons */
.yupluck-header-right {
  display: flex;
  gap: 15px;
}

.header-btn {
  padding: 8px 18px;
  font-weight: 500;
  font-size: 1rem;
  border-radius: 20px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  border: 2px solid #28a745;
}

.login-btn {
  background-color: #fff;
  color: #28a745;
}

.login-btn:hover {
  background-color: #28a745;
  color: #fff;
}

.register-btn {
  background-color: #28a745;
  color: #fff;
}

.register-btn:hover {
  background-color: #218838;
}


/* Top App Store Links */








/* Benefits Section */
.benefits {
  padding: 60px 20px;
  background: #fff;
  text-align: center;
}
.benefits h2 {
  margin-bottom: 40px;
  font-weight: 600;
}
.benefit-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}
.benefit-cards .card {
  background: #f9f9f9;
  border-radius: 12px;
  padding: 25px;
  max-width: 300px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.08);
}
.benefit-cards .card h5 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #28a745;
}

.card-title  {
 
  color: #333;
}

.card p {
 
  color: #333;
}

.promo-video-section {
  padding: 60px 20px;
  background: #f5f6f8;
  text-align: center;
}

.video-container {
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  border-radius: 12px;
  overflow: hidden;
}

.video-container video {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 12px;
}


/* Track & Rewards Section */
.track-rewards {
  display: flex;
  flex-wrap: wrap;
  padding: 60px 20px;
  background: #f0f8ff;
}
.track-left,
.track-right {
  flex: 1;
  padding: 20px;
}
.track-left img {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
}
.track-right h3 {
  margin-top: 20px;
  color: #222;
}
.track-right p {
  margin-bottom: 20px;
}

/* Download App */
.download-app {
  background: #fff;
  padding: 60px 20px;
  text-align: center;
}
.download-app h2 {
  font-weight: 600;
}
.download-app .app-links {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.download-app img {
  width: 150px;
  transition: transform 0.3s ease;
}
.download-app img:hover {
  transform: scale(1.05);
}

/* Footer */
.footer {
  background: #212529;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 0.9rem;
}

/* Responsive */
@media (max-width: 768px) {
  .hero-overlay h1 {
    font-size: 2rem;
  }
  .benefit-cards {
    flex-direction: column;
    align-items: center;
  }
  .track-rewards {
    flex-direction: column;
  }
  .top-app-links {
    justify-content: center;
  }
}
