.hero-cricheroes-style {
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 100px 20px;
    color: #fff;
    text-align: left;
    background-image: url('../img/yupluck-hero.png');
  }
  
  .overlay {
    background-color: rgb(83 160 127 / 70%);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
  
  .content-wrapper {
    position: relative;
    z-index: 1;
  }
  
  .hero-cricheroes-style h1 {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 20px;
    color: #fff;
  }
  
  .hero-cricheroes-style .highlight {
    color: #ffffff;
    background-color: #002f9d;
    padding: 4px 10px;
    border-radius: 4px;
  }
  
  .subtext {
    font-size: 1.3rem;
    margin-bottom: 30px;
  }
  
  .subtext .bold {
    font-weight: 600;
    background-color: #002f9d;
    padding: 3px 8px;
    border-radius: 4px;
  }
  
  .store-buttons {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  
  .store-buttons img {
    width: 160px;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .store-buttons img:hover {
    transform: scale(1.05);
  }
  
  .ratings {
    display: flex;
    gap: 50px;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }
  
  .rating-block {
    background: rgba(255, 255, 255, 0.15);
    padding: 10px 20px;
    border-radius: 8px;
  }
  
  .featured h5 {
    color: #fff;
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  
  .featured .logos {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .featured .logos img {
    height: 40px;
    background: white;
    padding: 5px;
    border-radius: 5px;
  }
  
  /* Responsive Media Queries */
  @media (max-width: 992px) {
    .hero-cricheroes-style {
      padding: 80px 15px;
    }
  
    .hero-cricheroes-style h1 {
      font-size: 2.5rem;
    }
  
    .subtext {
      font-size: 1.1rem;
    }
  
    .ratings {
      gap: 30px;
    }
  
    .store-buttons {
      justify-content: center;
    }
  }
  
  @media (max-width: 768px) {
    .hero-cricheroes-style {
      padding: 60px 15px;
      text-align: center;
    }
  
    .hero-cricheroes-style h1 {
      font-size: 2rem;
    }
  
    .subtext {
      font-size: 1rem;
    }
  
    .ratings {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  
    .store-buttons {
      justify-content: center;
    }
  
    .featured .logos {
      justify-content: center;
    }
  }
  
  @media (max-width: 480px) {
    .hero-cricheroes-style {
      padding: 50px 10px;
    }
  
    .hero-cricheroes-style h1 {
      font-size: 1.6rem;
    }
  
    .subtext {
      font-size: 0.95rem;
    }
  
    .store-buttons img {
      width: 140px;
    }
  
    .ratings {
      font-size: 0.9rem;
    }
  
    .featured h5 {
      font-size: 1rem;
    }
  
    .featured .logos img {
      height: 30px;
    }
  }
  