/* Login page styles */
.login-page {
  position: relative;
  height: 100vh;
  background-image: url('../img/yupluck-hero.png'); /* Replace with your actual path */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

/* Card styling */
.card {
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.25);
}

.card-body {
  padding: 2.5rem;
}

/* Title */
.card-title {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
}

/* Input fields */
.form-control {
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
}

/* Label */
label {
  font-weight: 600;
  color: #fff;
}

/* Buttons */
.btn-warning {
  background-color: #ffc107;
  border: none;
  font-weight: 600;
  transition: background 0.3s ease;
}

.btn-warning:hover {
  background-color: #e0a800;
}

.btn-outline-light {
  color: #fff;
  border: 2px solid #fff;
  font-weight: 600;
}

.btn-outline-light:hover {
  background-color: #fff;
  color: #000;
}

/* Forgot Password */
.text-center a {
  color: #f8f9fa;
  text-decoration: underline;
  font-size: 0.95rem;
  cursor: pointer;
}

/* Password toggle icon */
.password-toggle-icon {
  position: absolute;
  top: 38px;
  right: 10px;
  color: #ccc;
  cursor: pointer;
  z-index: 10;
}

.card-body h2{
  color: #333 !important;
}


.btn-register-outline {
  border: 2px solid #fff;
  color: #333;
  font-weight: 600;
  border-radius: 30px;
  padding: 12px;
  font-size: 1.1rem;
  width: 100%;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;
}

.btn-register-outline:hover {
  background-color: #fff;
  color: #000;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
}


/* Responsive */
@media (max-width: 576px) {
  .card-body {
    padding: 1.5rem;
  }

  .card-title {
    font-size: 1.75rem;
  }
}
