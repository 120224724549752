/* Booking Container */
.gym-display-container {
    margin-top: 60px;
    padding: 20px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
    font-family: 'Poppins', sans-serif;
    color: #333;
  }
  
  /* Header Section */
  .gym-display-container h2 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #007bff;
  }
  
  .btn-primary {
    font-size: 0.95rem;
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: 500;
  }
  
  /* Filter Row */
  .row .form-label {
    font-size: 0.85rem;
    font-weight: 500;
    color: #555;
    margin-bottom: 4px;
  }
  
  .row .form-control,
  .row .form-select {
    font-size: 0.9rem;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
  }
  
  /* Card Style */
  .card {
    border-radius: 12px;
    background: #fff;
    padding: 15px;
    margin-top: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }
  
  .card-body {
    padding: 0;
  }
  
  /* Table Styling */
  .table {
    font-size: 0.85rem;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    padding: 0.6rem 0.8rem;
    vertical-align: middle;
    white-space: nowrap;
  }
  
  .table th {
    background-color: #343a40;
    color: #fff;
    font-weight: 600;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9;
  }
  
  .table-hover tbody tr:hover {
    background-color: #f1f7ff;
  }
  
  /* Status Badges */
  .badge {
    font-size: 0.8rem;
    padding: 6px 12px;
    border-radius: 20px;
    font-weight: 600;
  }
  
  /* Booking Type Pill */
  .booking-type-pill {
    display: inline-block;
    padding: 6px 12px;
    border-radius: 15px;
    color: white;
    font-weight: bold;
    font-size: 0.85rem;
    text-transform: capitalize;
  }


  .stat-card {
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: white;
  height: 100%;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.stat-icon {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.stat-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 5px;
  letter-spacing: 0.5px;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
}

.bg-gradient-primary {
  background: linear-gradient(135deg, #007bff, #0056b3);
}

.bg-gradient-success {
  background: linear-gradient(135deg, #28a745, #1c7c31);
}

.bg-gradient-warning {
  background: linear-gradient(135deg, #ffc107, #ff9500);
}

.bg-gradient-info {
  background: linear-gradient(135deg, #17a2b8, #138496);
}



  
  /* Responsive */
  @media (max-width: 768px) {
    .gym-display-container h2 {
      font-size: 1.5rem;
    }
  
    .table {
      font-size: 0.8rem;
    }
  
    .form-control,
    .form-select {
      font-size: 0.85rem;
    }
  
    .badge {
      font-size: 0.75rem;
    }

    .stat-icon {
        font-size: 2rem;
      }
    
      .stat-value {
        font-size: 1.3rem;
      }
    
      .stat-title {
        font-size: 1rem;
      }
  }
  