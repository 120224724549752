.equipment-selection-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.next-button {
    display: block;
    margin: 0 0 10px auto;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.next-button:hover {
    background-color: #0056b3;
}

.header {
    text-align: center;
}

.title {
    font-size: 1.5em;
    color: #333;
    font-weight: bold;
    margin: 0;
}
h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.equipment-search {
    display: block;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.equipment-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.equipment-item {
    background: #fff;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
}

.equipment-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.equipment-icon {
    margin-bottom: 10px;
    height: 60px;
}

.equipment-name {
    font-weight: 600;
    color: #333;
    font-size: 1.1em;
    margin-bottom: 5px;
}

.equipment-description {
    font-size: 0.9em;
    color: #666;
}

.no-equipment-found {
    text-align: center;
    color: #888;
    font-size: 1.2em;
    margin-top: 20px;
}

.no-equipment-found i {
    font-size: 2em;
    color: #ff6961;
    margin-bottom: 10px;
}
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; /* Adjust as needed for spacing */
}

.title {
    margin: 0;
    font-size: 24px; /* You can adjust the size as needed */
}

.next-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.next-button:hover {
    background-color: #0056b3;
}