/* GymBenefits.css */
.gym-benefits {
    background-color: #f8f9fa;
    min-height: 100vh;
    padding-bottom: 40px;
}

h2 {
    font-weight: bold;
    color: #2c3e50;
}

.benefit-card {
    border: none;
    border-radius: 10px;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.benefit-card:hover {
    transform: translateY(-5px);
}

.benefit-icon {
    font-size: 3rem;
    color: #28a745;
}

.text-center p {
    font-size: 1.2rem;
    color: #555;
}

.btn-success {
    font-size: 1.2rem;
    padding: 10px 30px;
    border-radius: 25px;
}
